import React, { useEffect } from 'react';
import { QueryEditorProps } from '@grafana/data';
import {
  channelListObservable,
  DataSource,
  frequencyChannelListObservable,
  timeSeriesChannelListObservable,
} from '../datasource';
import { MyDataSourceOptions, MyQuery } from '../types';
import { Checkbox, InlineField, Input, RadioButtonGroup, VerticalGroup } from '@grafana/ui';
import useObservable from '../hooks/useObservable';

type Props = QueryEditorProps<DataSource, MyQuery, MyDataSourceOptions>;

export function QueryEditor(props: Props) {
  const { selectedChannels = [], dataType = 'TIMESERIES' } = props.query;

  const timeSeriesChannels = useObservable(timeSeriesChannelListObservable, []);
  const frequencyChannels = useObservable(frequencyChannelListObservable, []);

  const handleRefreshRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange({ ...props.query, refreshRate: Number(event.target.value) });
    props.onRunQuery();
  };

  const handleDataTypeChange = (dataType: MyQuery['dataType']) => {
    props.onChange({ ...props.query, dataType, selectedChannels: [] });
    props.onRunQuery();
  };

  const handleSelectedChannelsChange = (selectedChannels: MyQuery['selectedChannels']) => {
    props.onChange({ ...props.query, selectedChannels });
    props.onRunQuery();
  };

  useEffect(() => {
    const sub = channelListObservable.subscribe((channels) => {
      const filteredChannels = selectedChannels.filter((ch) => channels.some((c) => c.name === ch));
      if (JSON.stringify(filteredChannels) !== JSON.stringify(props.query.selectedChannels)) {
        props.onChange({ ...props.query, selectedChannels: filteredChannels });
        props.onRunQuery();
      }
    });

    return () => {
      sub.unsubscribe();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="gf-form pt-4">
      <VerticalGroup>
        <InlineField label="Refresh Rate">
          <Input type="number" value={props.query.refreshRate ?? 24} onChange={handleRefreshRateChange} />
        </InlineField>
        <RadioButtonGroup
          options={[
            {
              value: 'TIMESERIES' as const,
              label: 'TIMESERIES',
            },
            {
              value: 'FREQUENCY' as const,
              label: 'FREQUENCY',
            },
          ]}
          value={props.query.dataType || 'TIMESERIES'}
          onChange={handleDataTypeChange}
        />
        <div className="flex flex-wrap gap-2">
          {dataType === 'TIMESERIES' &&
            timeSeriesChannels.map((channel) => (
              <Checkbox
                key={channel.name}
                label={channel.name}
                value={selectedChannels.includes(channel.name)}
                onChange={(event) => {
                  const selectedChannels = event.currentTarget.checked
                    ? [...props.query.selectedChannels, channel.name]
                    : props.query.selectedChannels.filter((ch) => ch !== channel.name);
                  handleSelectedChannelsChange(selectedChannels);
                }}
              />
            ))}
          {dataType === 'FREQUENCY' &&
            frequencyChannels.map((channel) => (
              <Checkbox
                key={channel.name}
                label={channel.name}
                value={selectedChannels.includes(channel.name)}
                onChange={(event) => {
                  const selectedChannels = event.currentTarget.checked
                    ? [...props.query.selectedChannels, channel.name]
                    : props.query.selectedChannels.filter((ch) => ch !== channel.name);
                  handleSelectedChannelsChange(selectedChannels);
                }}
              />
            ))}
        </div>
      </VerticalGroup>
    </div>
  );
}
