import { DataSourceJsonData } from '@grafana/data';
import { DataQuery } from '@grafana/schema';
import { components } from 'api';

interface Config {
  selectedChannels: string[];
  refreshRate: number;
  dataType: 'TIMESERIES' | 'FREQUENCY';
}

export interface MyQuery extends DataQuery, Config {
  path: string;
  withStreaming: boolean;
}

export const DEFAULT_QUERY: Partial<MyQuery> = {
  path: '',
  withStreaming: true,
  selectedChannels: [],
  refreshRate: 24,
  dataType: 'TIMESERIES',
};

/**
 * These are options configured for each DataSource instance
 */
export interface MyDataSourceOptions extends DataSourceJsonData {
  path?: string;
}

/**
 * Value that is used in the backend, but never sent over HTTP to the frontend
 */
export interface MySecureJsonData {
  apiKey?: string;
}

export type Channel = components['schemas']['Channel'];
